import DDP from 'ddp.js'
import { deepClone} from "@/framework/utils/index"

let RocketClient = function() {
    let me = this
    let token = new LoginToken('', '')
    let ddpClient
    let loginSuccess = false
    let c = {
        host: '',
        port: '',
        username: '',
        password: ''
        // host: 'ws://10.230.38.87:3000/websocket',
        // port: '3000',
        // username: 'xichi',
        // password: 'xichi123456'
    }
    me.event = new RocketEventEmitter()

    this.signLoginState = function(a) {
        loginSuccess = !!a
    }

    this.isLogin = function() {
        return loginSuccess
    }

    this.ddp = function() {
        if (ddpClient) {
            return ddpClient
        } else {
            ddpClient = new DDP({
                // endpoint: 'ws://10.230.38.87:3000/websocket',
                endpoint: c.host,
                autoReconnect: true,
                SocketConstructor: WebSocket
            })

            return ddpClient
        }
    }

    this.getClassName = function() {
        return 'RocketClient'
    }

    /**  异常错误简单封装消息 */
    function RocketError(ac, err, mess) {
        let merror = this
        ////举个例子 ac参数 actionType 类本身没用
        let actionType = {
            login: '登陆',
            connect: '连接',
            send: '发送消息'
        }
        merror.error = 'im error'
        merror.action = '...'
        merror.message = '推送服务程序执行发生未知错误'

        if (!arguments) {
            return this
        } else {
            merror.error = err
            merror.action = ac
            merror.message = mess
        }
        return merror
    }

    this.error = function() {
        return new RocketError(arguments)
    }

    function LoginToken(authToken, userId) {
        let at = authToken
        let id = userId
        this.token = function() {
            return at
        }
        this.uid = function() {
            return id
        }
        return this
    }

    this.getToken = function() {
        return token
    }

    this.initToken = function(authToken, userId) {
        token = new LoginToken(authToken, userId)
    }
    /**  初始化登陆用参数 */
    this.initConfig = function(host, username, password) {
        if (host && username && password) {
            c.host = host
            c.username = username
            c.password = password
        }
        me.ddp()
        return me
    }
    this.getConfig = function() {
        return c
    }
}

class RocketEventEmitter {
    constructor() {
        this._events = {}
    }

    on(event, callback) {
        let callbacks = this._events[event] || []
        callbacks.push(callback)
        this._events[event] = callbacks
        return this
    }

    off(event, callback) {
        let callbacks = this._events[event]
        this._events[event] = callbacks && callbacks.filter(fn => fn !== callback)
        return this
    }

    emit(...args) {
        try {
            const event = args[0]
            const params = [].slice.call(args, 1)
            const callbacks = this._events[event]
            callbacks.forEach(fn => {
                try {
                    fn.apply(this, params)
                } catch (e) {
                }
            })
        } catch (e) {
        }
        return this
    }

    once(event, callback) {
        let wrapFunc = (...args) => {
            callback.apply(this, args)
            this.off(event, wrapFunc)
        }
        this.on(event, wrapFunc)
        return this
    }
}

/**
 * 异常编码  TODO 建议和rocketchat 保持一致  里面内容待完善
 * @type {{auth: string, connect: string}}
 */
const RocketErrorCon = {
    auth: 'authError',
    connect: ''
}
const RocketActionCon = {
    login: 'login',
    connect: 'connect',
    send: 'send'
}

/** TODO 消息类型 用于接受消息后续分类执行 ，具体内容持续完善,部分内容和后台的信息应该保持一致 ,代码应用在registerController */
const RequestEvent = {
    wellLog: 'wellLog',///单井监听
    wellLogCompany: 'wellLogCompany',///单位井监听
    wellWarn: 'wellWarn',///具体值无所谓
    wellWarnCompany: 'wellWarnCompany',///单位所有井警告无所谓
    wellMultij: 'wellMultij',///单井监听
    wellMultijCompany: 'wellMultijCompany',///单位井监听
    sysWarn: 'sysWarn',
    privateChat: 'privateChat',
    wellTesting: 'wellTesting',///单井测试监听
    wellTestingCompany: 'wellTestingCompany',///单位井测试监听
    wellCalculate: 'wellCalculate', // ecd计算
    wellCalculateCompany: 'wellCalculateCompany', // ecd计算
    fracWellLog: 'fracWellLog', // 压裂实时曲线
    prectlLog: 'prectlLog'
}

RocketClient.RequestEvent = RequestEvent

RocketClient.prototype.invokeBack = function(ac, error, mess, callback, res) {
    let me = this
    if (callback && typeof callback === 'function') {
        let er = null
        if (error) {
            er = me.error(ac, error, mess)
        }
        callback(res, er)
    }
    return me
}

/**  全局初始化*/
RocketClient.instance = function() {
    let client = window.ImClient
    if (client && client instanceof RocketClient) {
        return client
    } else {
        // let token =localStorage.getItem('chatToken')
        // let url =localStorage.getItem('url')
        // let user =localStorage.getItem('user')
        // let password =localStorage.getItem('password')
        // if(token){
        // client = new RocketClient().initConfig(url,user,password)
        // client.connectRocket()
        // client.loginNewDDP(token)
        // }else{
        client = new RocketClient().initConfig(arguments[0],arguments[1],arguments[2])
        client.connectRocket()
        client.loginDDP()
        window.ImClient=client;
        // }
    }
    return client
}
/**  rest-Api登陆 rocketchat服务 TODO*/
RocketClient.prototype.loginRestApi = function(userName, password) {
    let me = this
    let loginRes = { authToken: '', userId: '' }
    let url = ''

    const host = window.location.host.includes('localhost') ? process.env.VUE_APP_SOCKET_HOST : location.host;
    const protocol = window.location.host.includes('localhost') ? 'https:' : location.protocol

    url = protocol + '//' + host + '/r/api/v1/login'

    function ajax(u,username,password){
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.overrideMimeType("text/html");
        xmlhttp.open("POST",u,false);
        xmlhttp.setRequestHeader("Content-Type","application/x-www-form-urlencoded; charset=UTF-8");
        xmlhttp.setRequestHeader("Accept","application/json, text/javascript, */*; q=0.01");
        xmlhttp.send("user="+username+"&password="+password);
        var a = xmlhttp.responseText;
        a=JSON.parse(a);
        return a;
    }
    var data = ajax(url,userName,password);
    // console.log(data)
    if (data.data) {
        loginRes.authToken = data.data.authToken;
        loginRes.userId = data.data.userId;
        // api.loginRocketChat(username,password)  TODO
        //// 登陆返回值  TODO
        me.initToken(loginRes.authToken, loginRes.userId)
        return me
    } else {
        return null
    }

}
RocketClient.prototype.loginDDP = function() {
    let me = this

    ///TODO 登陆 rest-api  获取token  注意api 同步执行 不要异步
    let loginToken = me.loginRestApi(me.getConfig().username, me.getConfig().password) ? me.loginRestApi(me.getConfig().username, me.getConfig().password).getToken() : null
    if (loginToken) {
        me.ddp().method('login', [{
            resume: loginToken.token()
        }])
        me.signLoginState(true)
        window.ImClient = me
    } else {
        window.ImClient = null
    }
}
RocketClient.prototype.loginNewDDP = function(token) {
    let me = this

    ///TODO 登陆 rest-api  获取token  注意api 同步执行 不要异步
    //let loginToken = me.loginRestApi(me.getConfig().username, me.getConfig().password).getToken()
    me.ddp().method('login', [{
        resume: token
    }])
    me.signLoginState(true)
    window.ImClient = me
}

RocketClient.prototype.logout = function(callback) {
    ///TODO 链接  不要return this
}

RocketClient.prototype.connectRocket = function() {
    let me = this
    me.ddp().on('connected', () => {
    })
    me.ddp().connect()

    me.ddp().on('ready', message => {
        // // console.log('ddp ready', message)
    })

    me.ddp().on('removed', message => {
        // // console.log('ddp removed', message)
    })
    me.ddp().on('added', message => {
        // // console.log('ddp added', message)
    })

    me.ddp().on('result', message => {
        // // console.log('ddp result', message)
    })
    return me
}
/** TODO 格式化消息数据  很重要 */
RocketClient.prototype.serialMessage = function(message) {
    let me = this
    let result
    ///TODO  解析整个socket文本   区分出有用消息 进行下一步处理
    //////  1判断哪些消息是 房间推送的消息
    //////  2根据事件范围判断， 房间推送消息
    /** 需格式化出RequestEvent的类型 方便后续分类执行  TODO */
    /**  TODO   消息内容解析*/
    function ControllerMessage(res) {
        window.openLog ? console.log("原始消息", res) : null
        this.resData = JSON.parse(res.msg)
        this.wellboreId = this.resData.wellboreId || ''
        this.companyId=this.resData.companyId || ''
        this.id=res._id
        this.data=this.resData.data
        this.msgType = this.resData.msgType
        if(this.msgType==='log'){
            this.scopeEvent = [RocketClient.RequestEvent.wellLog]
            this.singleEvent = [me.serialEventName(RocketClient.RequestEvent.wellLog,this.wellboreId),me.serialEventName(RocketClient.RequestEvent.wellLogCompany,this.companyId)]
        }
        if(this.msgType==='warn'){
            this.scopeEvent = [RocketClient.RequestEvent.wellWarn]
            this.singleEvent = [me.serialEventName(RocketClient.RequestEvent.wellWarn,this.wellboreId),me.serialEventName(RocketClient.RequestEvent.wellWarnCompany,this.companyId)]
        }
        if(this.msgType==='multij'){
            this.scopeEvent = [RocketClient.RequestEvent.wellMultij]
            this.singleEvent = [me.serialEventName(RocketClient.RequestEvent.wellMultij,this.wellboreId),me.serialEventName(RocketClient.RequestEvent.wellMultijCompany,this.companyId)]
        }
        if(this.msgType==='chat'){
            this.scopeEvent = [RocketClient.RequestEvent.privateChat]
            this.singleEvent = [me.serialEventName(RocketClient.RequestEvent.privateChat)]
        }
        if(this.msgType==='Testing'){
            this.scopeEvent = [RocketClient.RequestEvent.wellTesting]
            this.singleEvent = [me.serialEventName(RocketClient.RequestEvent.wellTesting,this.wellboreId),me.serialEventName(RocketClient.RequestEvent.wellTestingCompany,this.companyId)]
        }
        if(this.msgType==='calculate'){
            this.scopeEvent = [RocketClient.RequestEvent.wellCalculate]
            this.singleEvent = [me.serialEventName(RocketClient.RequestEvent.wellCalculate,this.wellboreId),me.serialEventName(RocketClient.RequestEvent.wellCalculateCompany,this.companyId)]
        }
        if(this.msgType==='Frac'){
            window.openLog ? console.log("Frac消息", this.data) : null
            this.scopeEvent = [RocketClient.RequestEvent.fracWellLog]
            this.singleEvent = [me.serialEventName(RocketClient.RequestEvent.fracWellLog,this.wellboreId),me.serialEventName(RocketClient.RequestEvent.wellCalculateCompany,this.companyId)]
        }
        if(this.msgType === 'Prectl') {
            this.scopeEvent = [RocketClient.RequestEvent.prectlLog]
            this.singleEvent = [me.serialEventName(RocketClient.RequestEvent.prectlLog,this.wellboreId),me.serialEventName(RocketClient.RequestEvent.wellCalculateCompany,this.companyId)]
        }
        return this
    }
    let cm= new ControllerMessage(message)
    return cm

}
/** 消息内部通知，触发相应事件 TODO */
RocketClient.prototype.notifyEvent = function(cm) {
    let me = this
    let res = deepClone(cm.resData)
    for (let en of cm.scopeEvent) {
        me.event.emit(en, res)
    }
    for (let en of cm.singleEvent) {
        me.event.emit(en, res)
    }
}
/**  解析事件名称 TODO */
RocketClient.prototype.serialEventName = function() {
    let arg = arguments
    if (arg[1]) {
        return arg[0] + '.' + arg[1]
    } else {
        return arg[0]
    }
}
/*** 执行指定消息的控制方法  TODO **/
RocketClient.prototype.invokeController = function(message) {
    let me = this
    function formateSocketMessage(message){
        let validate = message.fields.args[1].lastMessage
        //validate = JSON.parse(validate)
        return validate
    }
    let msg = formateSocketMessage(message)
    if(msg){
        let controllerMessage = me.serialMessage(msg)
        me.notifyEvent(controllerMessage)
    }



    // let wellboreId = '53'
    // let groupId = '33'
    // me.event.emit("wellWarn"+'.'+"53",cm.message)
    // me.event.emit("wellWarn",cm.message)
    // me.event.emit(cm.event + '.' + wellboreId, cm.message)
    // me.event.emit(cm.event, cm.message)xxxxxxxx
}
/*** 注册指定消息的控制方法 event 指的是 RocketClient.RequestEvent **/
RocketClient.prototype.register = function() {
    let arg = arguments
    let event, id, fn
    if (arg && arg.length > 1) {
        event = arg[0]
        if (arg.length === 2) {
            fn = arg[1]
        }
        if (arg.length === 3) {
            id = arg[1]
            fn = arg[2]
        }
    } else {
        return
    }
    let me = this
    let eventName = me.serialEventName(event, id)
    me.event.on(eventName, fn)
}
/*** 执行指定消息的控制方法**/
RocketClient.prototype.unRegister = function() {
    let arg = arguments
    let event, id, fn
    if (arg && arg.length > 1) {
        event = arg[0]
        if (arg.length === 2) {
            fn = arg[1]
        }
        if (arg.length === 3) {
            id = arg[1]
            fn = arg[2]
        }
    } else {
        return
    }
    let me = this
    let eventName = me.serialEventName(event, id)
    me.event.off(eventName, fn)
    // let me = this
    // me.event.off(event, controller)
}

/**TODO  取消监听消息*/
RocketClient.prototype.stopMessageListener = function(event, callback) {

}

/**TODO  监听消息*/
RocketClient.prototype.startMessageListener = function() {
    let me = this
    me.subscribeUser(function() {
    })
    me.ddp().on('changed', message => {
        try{
            me.invokeController(message)
        }catch(e){}
    })
    ///TODO
    // me.ddp().on('message', function(msg) {
    //   // console.log('Subscription userMsg: ' + msg)
    // })
    //TODO 成接收到消息后执行

}
/**TODO  取消订阅 参数待完善*/
RocketClient.prototype.unsubscribe = function(event, callback) {
}
/**TODO  订阅 参数待完善*/
RocketClient.prototype.subscribe = function(event, callback) {
}
RocketClient.prototype.subscribeUser = function(callback) {
    let me = this
    ////订阅当前用户所有房间消息  TODO
    let uid = me.getToken().uid()
    let subId = me.ddp().sub('stream-notify-user', [uid + '/rooms-changed', false])

    return me.invokeBack(null, false, null, callback, '')
}
export default RocketClient

