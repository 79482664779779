import axios from "axios";
import { Message } from "element-ui";
import store from "@/common_store";
import { getToken } from "@/framework/utils/auth";

axios.defaults.withCredentials = true;

const service = axios.create({
  timeout: 120000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    if (config.url.indexOf("login/") !== -1) {
      return config;
    }
    if (store.getters.token) {
      config.headers["Authorization"] = getToken(); // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
    }
    config.headers["Accept"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// respone interceptor
service.interceptors.response.use(
  (response) => {
    if (JSON.stringify(response.headers) === "{}") {
      return response;
    } else {
      const error = response.error;
      if (error) {
        return Promise.reject(response);
      }
      // 捕捉500异常
      if (response.data.code == 500) {
        Message({
          message: response.data.msg,
          type: "error",
          duration: 5 * 1000,
        });
        return Promise.reject(response);
      }
      const data = response.data;
      if (data.code === 0 || data.code === 200 || data["access_token"] || data.code === 1) {
        return data; // 正常处理直接返回需要接受的数据
      } else {
        // Message({message: data.msg, type: 'error'})
        // 这里假设code返回不为 0 表示， 就直接返回错误的处理
        return Promise.reject(data);
      }
    }
  },

  (error) => {
    console.log("response", error);

    if (error.request.status === 401) {
      window.location.href = location.origin + "/logout";
    }
    // error.request.status 此处可以判断状态码做全局状态异常处理
    // // console.log('err' + error)// for debug
    let message = error.message;
    if (error.response.data) {
      message = error.response.data.msg;
    }
    Message({
      message: message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
